import React, { createElement, useEffect } from "react";
import { useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ErrorPage } from "./Components";
import { GlobalState } from "./Data/Context";
import { Loader } from "./Utils";

const generatePage = (pageName, folder) => {
  const component = () => require(`./${folder}/${pageName}`).default;

  try {
    return createElement(component());
  } catch (error) {
    return <ErrorPage />;
    console.log(error);
  }
};

const PageRender = () => {
  const { auth, errors, clearErrors } = useContext(GlobalState);
  const { page, id, step } = useParams(),
    navigate = useNavigate(),
    escapeUsers = [
      "reseller",
      "users",
      "topuser",
      "upgrade",
      "agent",
      "wallet",
      "patrons",
      "registration",
    ],
    escape = ["pending", "failed", "urgent-pendings"],
    escapeProducts = [
      "airtime",
      "data",
      "tv-subscriptions",
      "data-pin",
      "education",
      "biz",
      "verification",
      "cgwallet",
      "auto-buy",
      "electricity-bills",
      "verification-type",
    ],
    escapeAdmin = [
      "manager",
      "marketer",
      "billers",
      "superadmin",
      "developer",
      "accountant",
    ],
    escapeWallet = [
      "bonus",
      "give-bonus",
      "bonus-data",
      "commissions",
      "referral",
      "wallet-funding",
      "my-wallet",
      "provider",
      "manage-transactions",
      "find-transactions",
      "manage-wallet",
      "manual",
      "manual-debit",
      "withdrawal",
      "wallet-funding",
    ],
    escapeControl = [
      "electricity",
      "startimes",
      "broadcasts",
      "cables",
      "bonus",
      "data",
      "category",
      "biller",
      "gateway",
      "network",
      "education",
      "manage-products",
      "manage-cgwallet",
      "manage-cgwallet-history",
      "blacklist",
    ];

  useEffect(() => {
    if (!auth?.isAuth) {
      if (errors?.errorText) {
        if (page !== "login" && page !== "register") {
          navigate("/");
        }
        clearErrors();
      }
    }
    if (auth?.isAuth) {
      if (page === "login" || page === "register") {
        navigate("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, auth?.isAuth, navigate, errors?.errorText]);

  if (auth.token && auth.loading) return <Loader />;

  let pageName = "";
  if (step) {
    if (["biz", "verification"]?.includes(id) && ["verify"].includes(step))
      pageName = `${page}/${id}/${step}`;
    else if (
      id === "commissions" &&
      ["stat", "manual", "general"].includes(step)
    )
      pageName = `${page}/${id}/${step}`;
    else if (id === "app" && ["terms", "privacy", "serviceicon"].includes(step))
      pageName = `${page}/${id}/${step}`;
    else pageName = `${page}/${id}/${"[id]"}`;
  } else if (id) {
    if (page === "users" && escapeUsers.includes(id))
      pageName = `${page}/${id}`;
    else if (page === "products" && escapeProducts.includes(id))
      pageName = `${page}/${id}`;
    else if (page === "controls" && escapeControl.includes(id))
      pageName = `${page}/${id}`;
    else if (page === "administration" && escapeAdmin.includes(id))
      pageName = `${page}/${id}`;
    else if (page === "transactions" && escape.includes(id))
      pageName = `${page}/${id}`;
    else if (page === "wallets" && escapeWallet.includes(id))
      pageName = `${page}/${id}`;
    else if (page === "activities" && ["general"].includes(id))
      pageName = `${page}/${id}`;
    else if (page === "settings" && ["app"].includes(id))
      pageName = `${page}/${id}`;
    else pageName = `${page}/[id]`;
  } else {
    pageName = `${page}`;
  }

  return generatePage(pageName, auth?.user ? "Pages" : "Screens");
};

export default PageRender;
