import React, { useContext, useState, useEffect } from "react";
import { Container } from "reactstrap";
import { GlobalState } from "../../Data/Context";
import { MainPaginate, MainRanger } from "../../Components/Transactions";
import { EmptyComponent } from "../../Utils";
import { useNavigate, useParams } from "react-router-dom";
import { ExportButtons2 } from "../../Utils/Export Functions/ExportButtons";

const KeyPatrons = () => {
  let {
      keyPatron,
      getUserKeyPatrons,
      nairaSign,
      numberWithCommas,
      setStateName,
      getReload,
      auth,
      permission,
    } = useContext(GlobalState),
    [search, setSearch] = useState(""),
    [state, setState] = useState(null);

  let tabControl = ["day", "week", "month"],
    [active, setActive] = useState("day"),
    navigate = useNavigate();

  useEffect(() => {
    if (auth?.user?.privilege !== "superadmin") {
      let findTitle = "viewmanagekeypatrons";

      let findPermit = permission?.data?.find(
        (item) =>
          item?.slug === findTitle && item?.user?.includes(auth?.user?._id)
      );
      if (!findPermit) {
        navigate(-1);
      }
    }
  }, [auth?.user, permission?.data, navigate]);

  useEffect(() => {
    setStateName("manage Key Patrons");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (search) {
      document.getElementById("Search").addEventListener("search", () => {
        getReload();
      });
      let handleSubmit = async () => {
        if (!search) return;

        await getUserKeyPatrons(active, { search });
      };
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, active]);

  useEffect(() => {
    if (keyPatron.isFound) {
      setState(keyPatron.mainSearch || []);
    } else if (active === "month") {
      setState(keyPatron.month || []);
    } else if (active === "week") setState(keyPatron?.week || []);
    else setState(keyPatron?.day || []);
  }, [
    keyPatron?.day,
    keyPatron?.month,
    keyPatron?.week,
    active,
    keyPatron?.isFound,
    keyPatron?.mainSearch,
  ]);

  useEffect(() => {
    getUserKeyPatrons(active);
    // if (active) getReload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  let [range, setRange] = useState(10);

  const params = useParams();

  const [pageCount, setPageCount] = useState(
    keyPatron?.paginate
      ? Math.ceil(keyPatron?.paginate?.total / keyPatron?.paginate?.limit)
      : 1
  );

  useEffect(() => {
    if (keyPatron?.search_paginate) {
      setPageCount(
        Math.ceil(
          keyPatron?.search_paginate?.total / keyPatron?.search_paginate?.limit
        )
      );
    } else {
      setPageCount(
        Math.ceil(keyPatron?.paginate?.total / keyPatron?.paginate?.limit)
      );
    }
  }, [keyPatron?.search_paginate, keyPatron?.paginate]);

  // const [itemOffset, setItemOffset] = useState(0);
  // const endOffset = itemOffset + range;

  if (!state) return;

  const currentItems = state;

  // const currentItems = state.slice(itemOffset, endOffset);
  // const pageCount = Math.ceil(state.length / range);

  const handlePageClick = async (event) => {
    // const newOffset = (event.selected * range) % state.length;
    // setItemOffset(newOffset);
    await getUserKeyPatrons(active, {
      page: Number(event?.selected + 1),
      search,
    });
  };

  const headers = [
    { title: "NAME", field: "userName" },
    { title: "EMAIL", field: "email" },
    { title: "NUMBER", field: "number" },
    { title: "PRIVILEGE", field: "privilege" },
    { title: "TOTAL AMOUNT", field: "total" },
  ];

  const data = currentItems.map((item, index) => ({
    userName: `${item?.user?.firstName} ${item?.user?.lastName}`,
    email: `${item?.user?.email}`,
    number: `${item?.user?.telephone}`,
    privilege: `${item?.user?.privilege}`,
    total: `₦ ${numberWithCommas(Number(item?.data || 0).toFixed(2))}`,
  }));

  return (
    <div className="py-4 bg-white aboutScreen">
      <Container className="py-5">
        <div className="w-50 w50 mb-3">
          <input
            type="search"
            name="search"
            id="Search"
            className="form-control w-100 py-3 borderColor2"
            placeholder="Type here to search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className="btn-group w-100 py-3">
          {tabControl?.map((item, i) => (
            <button
              key={i}
              className={`btn py-3 text-capitalize fw-bold ${
                active === item ? "border-bottom textColor" : ""
              } rounded-0`}
              onClick={() => {
                getReload();
                setActive(item);
              }}
            >
              {item}
            </button>
          ))}
        </div>
        <div className="tw-flex tw-justify-between tw-items-center tw-mb-2">
          <div className="tw-flex-1">
            {/* <MainRanger range={range} setRange={setRange} /> */}
          </div>
          <ExportButtons2
            headers={headers}
            data={data}
            fileName={`${params?.page} ${params?.id && `(${params?.id})`}`}
          />
        </div>
        <div className="bland row mx-0 py-3 text-capitalize">
          <div className="col textTrunc text-uppercase fontReduce fw-bold Lexend">
            Name
          </div>
          <div className="col textTrunc text-uppercase fontReduce fw-bold Lexend">
            Email
          </div>
          <div className="col textTrunc text-uppercase fontReduce fw-bold Lexend">
            Number
          </div>
          <div className="col textTrunc text-uppercase fontReduce fw-bold Lexend">
            Privilege
          </div>
          <div className="col textTrunc text-uppercase fontReduce fw-bold Lexend">
            Total Amount
          </div>
        </div>
        <div className="bland2 row mx-0">
          {currentItems?.length === 0 ? (
            <EmptyComponent subtitle={`Wallet Tracking list is empty`} />
          ) : (
            currentItems?.map((item, index) => (
              <div key={index} className="row mx-0 p-3 border-bottom">
                <div className="col fontReduce textTrunc my-auto">
                  {item?.user?.firstName} {item?.user?.lastName}
                </div>
                <div className="col fontReduce2 textTrunc my-auto">
                  {item?.user?.email}
                </div>
                <div className="col fontReduce textTrunc my-auto">
                  {item?.user?.telephone}
                </div>
                <div className="col fontReduce textTrunc my-auto text-capitalize">
                  {item?.user?.privilege}
                </div>
                <div className="col fontReduce2 textTrunc my-auto d-flex w-100">
                  <span className="fontInherit me-md-1">{nairaSign}</span>{" "}
                  <span>
                    {numberWithCommas(Number(item?.data || 0).toFixed(2))}
                  </span>
                </div>
              </div>
            ))
          )}
        </div>
        <MainPaginate handlePageClick={handlePageClick} pageCount={pageCount} />
      </Container>
    </div>
  );
};

export default KeyPatrons;
