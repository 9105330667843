import React, { useContext, useEffect, useState } from "react";
import { GlobalState } from "../../../Data/Context";
import { Container } from "reactstrap";
import LoadMore, { BottomTab } from "../../../Components/LoadMore";
import moment from "moment";
import { EmptyComponent } from "../../../Utils";
import { MainPaginate, MainRanger } from "../../../Components/Transactions";
import { BonusCommission } from "../../../Components/Wallets";
import { ExportButtons2 } from "../../../Utils/Export Functions/ExportButtons";
import { useParams } from "react-router-dom";

const Referral = () => {
  let { setStateName } = useContext(GlobalState),
    [active, setActive] = useState(0);

  useEffect(() => {
    setStateName("Referral history");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bg-white aboutScreen">
      <Container className="py-3 py-md-5">
        <h5 className="Lexend">Referral History</h5>
        <div className="btn-group w-100 py-3">
          <button
            className={`btn py-3 text-capitalize fw-bold ${
              active === 0 ? "border-bottom textColor" : ""
            } rounded-0`}
            onClick={() => setActive(0)}
          >
            Referral User<span className="text-lowercase">(s)</span>
          </button>
          <button
            className={`btn py-3 text-capitalize fw-bold ${
              active === 1 ? "border-bottom textColor" : ""
            } rounded-0`}
            onClick={() => setActive(1)}
          >
            Referred Bonus
          </button>
        </div>
        {active === 1 ? (
          <BonusCommission type={"referral"} general={"general"} />
        ) : (
          <ReferedUser />
        )}{" "}
      </Container>{" "}
    </div>
  );
};

export default Referral;

const ReferedUser = () => {
  const { getReferrals, referral, getReload } = useContext(GlobalState);

  let [state, setState] = useState(null),
    [search, setSearch] = useState(null);

  useEffect(() => {
    getReferrals({ general: "allCases" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let [loading, setLoading] = useState(false);
  // let handleLoadMore = async () => {
  //   setLoading(true);

  //   await getReferrals({
  //     page: Number(
  //       search
  //         ? referral?.search_paginate?.nextPage
  //         : referral?.general_paginate?.nextPage
  //     ),
  //     // limit: Number(
  //     // 	search
  //     // 		? referral?.search_paginate?.nextPage *
  //     // 				referral?.search_paginate?.limit
  //     // 		: referral?.general_paginate?.nextPage *
  //     // 				referral?.general_paginate?.limit
  //     // ),
  //     general: "allCases",
  //     search,
  //   });
  //   setLoading(false);
  // };

  useEffect(() => {
    getReload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (referral?.isFound) setState(referral?.mainSearch);
    else setState(referral?.all_referral);
  }, [referral, referral?.isFound, referral?.mainSearch]);

  useEffect(() => {
    if (search) {
      document.getElementById("Search").addEventListener("search", () => {
        getReload();
      });
      let handleSubmit = async () => {
        if (!search) return;

        await getReferrals({
          general: "allCases",
          search,
        });
      };
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    if (referral?.isFound) {
      setPageCount(
        Math.ceil(
          referral?.search_paginate?.total / referral?.search_paginate?.limit
        )
      );
    } else {
      setPageCount(
        Math.ceil(referral?.all_paginate?.total / referral?.all_paginate?.limit)
      );
    }
  }, [referral?.isFound, referral?.search_paginate, referral?.all_paginate]);

  // let [range, setRange] = useState(10);
  const params = useParams();

  // const [itemOffset, setItemOffset] = useState(0);
  // const endOffset = itemOffset + range;
  if (!state) return;

  // const currentItems = state.slice(itemOffset, endOffset);
  const currentItems = state;

  // const pageCount = Math.ceil(state.length / range);

  const handlePageClick = async (event) => {
    // const newOffset = (event.selected * range) % state.length;
    // setItemOffset(newOffset);

    await getReferrals({
      page: Number(event?.selected + 1),

      general: "allCases",
      search,
    });
  };

  const headers = [
    { title: "S/N", field: "serialNumber" },
    { title: "Referee", field: "referee" },
    { title: "User", field: "userName" },
    { title: "Date & Time", field: "createdAt" },
  ];

  const data = currentItems.map((item, index) => ({
    serialNumber: index + 1,
    referee: `${item?.referee?.firstName} ${item?.referee?.lastName}`,
    userName: `${item?.user?.firstName} ${item?.user?.lastName}`,
    createdAt: moment(item?.createdAt).format("DD/MM/YYYY hh:mm A"),
  }));

  return (
    <div className="py-5">
      <div className="w-50 w50 mb-3">
        <input
          type="search"
          name="search"
          id="Search"
          className="form-control w-100 py-3 borderColor2"
          placeholder="Type here to search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className="tw-flex tw-justify-between tw-items-center">
        <div className="tw-flex-1">
          {/* <MainRanger range={range} setRange={setRange} /> */}
        </div>
        <ExportButtons2
          headers={headers}
          data={data}
          fileName={`${params?.page} ${params?.id && `(${params?.id})`}`}
        />
      </div>
      <div className="bland row mx-0 py-3 px-0 text-capitalize">
        <div className="col textTrunc fontReduce fw-bold Lexend">S/N</div>
        <div className="col textTrunc fontReduce fw-bold Lexend">Referee</div>
        <div className="col textTrunc fontReduce fw-bold Lexend">User</div>
        <div className="col textTrunc fontReduce fw-bold Lexend">
          Data & Time
        </div>
      </div>
      <div className="bland2 row mx-0">
        {currentItems?.length === 0 ? (
          <EmptyComponent subtitle={`Referral list is empty`} />
        ) : (
          currentItems?.map((item, index) => (
            <div key={index} className="row mx-0 py-3 px-0">
              <div className="col textTrunc fontReduce2 my-auto textTrunc textTrunc3">
                {index + 1}
              </div>
              <div className="col textTrunc fontReduce2 my-auto">
                {item?.referee?.firstName} {item?.referee?.lastName}
              </div>
              <div className="col textTrunc fontReduce2 my-auto">
                {item?.user?.firstName} {item?.user?.lastName}
              </div>
              <div className="col textTrunc fontReduce2 my-auto">
                {moment(item?.createdAt).format("DD/MM/YYYY hh:mm A")}
              </div>
            </div>
          ))
        )}
      </div>
      <MainPaginate handlePageClick={handlePageClick} pageCount={pageCount} />
      <BottomTab
        state={state}
        paginate={
          referral?.isFound ? referral?.search_paginate : referral?.all_paginate
        }
      />
      {/* <LoadMore
        next={
          referral?.isFound
            ? referral?.search_paginate?.next
            : referral?.all_paginate?.next
        }
        handleLoadMore={handleLoadMore}
        loading={loading}
      /> */}
    </div>
  );
};
