import axios from "axios";
import { toast } from "react-toastify";
import { returnErrors } from "../Reducer/ErrorReducer";
import {
  ADD_BILLER,
  ADD_BILLER_FAIL,
  ADD_BILLER_FUNDING,
  ADD_BILLER_FUNDING_FAIL,
  ADD_CATEGORY,
  ADD_CATEGORY_FAIL,
  ADD_GATEWAY,
  ADD_GATEWAY_FAIL,
  ADD_NETWORK,
  ADD_NETWORK_FAIL,
  ADD_PRODUCTS,
  ADD_PRODUCTS_FAIL,
  COMMISSION_TRANSACTIONS_STAT,
  DATA_TRANSACTIONS_STAT,
  DELETE_BILLER,
  DELETE_CATEGORY,
  DELETE_GATEWAY,
  DELETE_NETWORK,
  DELETE_PRODUCTS,
  FIND_TRANSACTIONS_STAT,
  GET_AUTOSYNC_DATA,
  GET_BILLER,
  GET_BILLER_AUTOSYNCNG,
  GET_BILLER_CHOOSEN,
  GET_BILLER_FAIL,
  GET_BILLER_FUNDING,
  GET_BILLER_HONOURWORLD,
  GET_BILLER_OGDAMS,
  GET_BILLER_RINGO,
  GET_BILLER_RINGO_FAIL,
  GET_BILLER_SMEPLUG,
  GET_BUDPAY,
  GET_CATEGORY,
  GET_CATEGORY_FAIL,
  GET_CHOSEN_SERVICES,
  GET_CHOSEN_SERVICES_FAIL,
  GET_DATA_BUNDLE,
  GET_DATA_BUNDLE_FAIL,
  GET_FLUTTERWAVE,
  GET_GATEWAY,
  GET_GATEWAY_FAIL,
  GET_HONOURWORLD_DATA,
  GET_HONOURWORLD_DATA_FAIL,
  GET_MANUAL,
  GET_MCD,
  GET_MCD_FAIL,
  GET_MONNIFY,
  GET_NETWORK,
  GET_OGDAMS,
  GET_OGDAMS_FAIL,
  GET_PAYSTACK,
  GET_PRODUCTS,
  GET_PRODUCTS_FAIL,
  GET_PROVIDER_FAIL,
  GET_RINGO_DATA,
  GET_RINGO_DATA_FAIL,
  GET_RPI_DATA,
  GET_RPI_DATA_FAIL,
  GET_RPI_DATA_PLAN,
  GET_TRANSFER,
  GET_UZOBEST,
  GET_UZOBEST_BALANCE,
  GET_UZOBEST_BALANCE_FAIL,
  SEARCH_BILLER,
  SEARCH_BILLER_FAIL,
  SEARCH_BILLER_LOADING,
  SEARCH_CATEGORY,
  SEARCH_CATEGORY_FAIL,
  SEARCH_CATEGORY_LOADING,
  SEARCH_GATEWAY,
  SEARCH_GATEWAY_FAIL,
  SEARCH_GATEWAY_LOADING,
  SEARCH_PRODUCTS,
  SEARCH_PRODUCTS_FAIL,
  SEARCH_PRODUCTS_LOADING,
  SEARCH_PROVIDER,
  SEARCH_PROVIDER_FAIL,
  SEARCH_PROVIDER_LOADING,
  SET_SUCCESS,
  TRANSACTIONS_STAT,
  UPDATE_BILLER,
  UPDATE_CATEGORY,
  UPDATE_GATEWAY,
  UPDATE_NETWORK,
  UPDATE_PRODUCTS,
  USER_REGISTRATION_STAT,
  WALLET_PROVIDER_STAT,
} from "./ActionTypes";
import { imageUpload } from "./AuthActions";

import {
  getCablesPackages,
  getDataBundle,
  getDataToBuy,
  getElectricityBundle,
  getElectricityToBuy,
} from "./DataActions";
import { useURL, useURL2, useURL3, useURL4, useURL5, useURL6 } from "../Config";
import { networkData } from "../data";

export const getBillerBalances = () => async (dispatch) => {
  try {
    let res = await axios.get(`/api/v2/biller/balances`, {
      baseURL: useURL3 || useURL,
    });

    const ringoData = res.data?.data?.balances.find((item) => {
      return item?.name === "ringo";
    });
    const ogdamsData = res.data?.data?.balances.find((item) => {
      return item?.name === "ogdams";
    });
    const chosenData = res.data?.data?.balances.find((item) => {
      return item?.name === "chosen";
    });
    const mcdData = res.data?.data?.balances.find((item) => {
      return item?.name === "mcd";
    });
    const uzobestData = res.data?.data?.balances.find((item) => {
      return item?.name === "uzobest";
    });
    const autosyncData = res.data?.data?.balances.find((item) => {
      return item?.name === "autosync ng";
    });

    const filterData = (data) => {
      let filteredData = Object.entries(data)
        .filter(([key, value]) => parseFloat(value) > 0) // Keep only keys with values > 0
        .reduce((acc, [key, value]) => {
          acc[key] = value; // Rebuild the filtered object
          return acc;
        }, {});
      return filteredData;
    };

    dispatch({
      type: GET_CHOSEN_SERVICES,
      payload: chosenData ? filterData(chosenData.data[0]) : null,
    });
    dispatch({
      type: GET_AUTOSYNC_DATA,
      payload: autosyncData ? filterData(autosyncData.data) : null,
    });
    dispatch({
      type: GET_UZOBEST_BALANCE,
      payload: uzobestData ? uzobestData.data : null,
    });
    dispatch({
      type: GET_OGDAMS,
      payload: ogdamsData ? filterData(ogdamsData.data.data.msg) : null,
    });
    dispatch({
      type: GET_RINGO_DATA,
      payload: ringoData ? ringoData.data : null,
    });
    dispatch({
      type: GET_MCD,
      payload: mcdData ? mcdData.data.data : null,
    });
  } catch (err) {
    if (err) console.log({ err });
    if (err) console.log(err?.response ? err?.response?.data : err?.message);

    let error = err.response?.data?.error;

    if (error) dispatch(returnErrors({ error, status: err?.response?.status }));
  }
};

export const getChoosenServices = () => async (dispatch) => {
  // try {
  //   let res = await axios.get(`/api/v2/biller/chosen/balance`, {
  //     baseURL: useURL3 || useURL,
  //   });
  //   dispatch({
  //     type: GET_CHOSEN_SERVICES,
  //     payload: res.data,
  //   });
  // } catch (err) {
  //   if (err) console.log(err.response?.data?.error, { err });
  //   if (err?.response?.status === 429 || err?.response?.status === 405)
  //     toast.error(err?.response?.data ? err?.response?.data : err?.message);
  //   dispatch({
  //     type: GET_CHOSEN_SERVICES_FAIL,
  //   });
  // }
};
export const getRpiData = () => async (dispatch) => {
  // try {
  //   let res = await axios.get(`/api/v2/biller/rpi/balance`, {
  //     baseURL: useURL3 || useURL,
  //   });
  //   dispatch({
  //     type: GET_RPI_DATA,
  //     payload: res.data,
  //   });
  // } catch (err) {
  //   if (err) console.log(err.response?.data?.error, { err });
  //   if (err?.response?.status === 429 || err?.response?.status === 405)
  //     toast.error(err?.response?.data ? err?.response?.data : err?.message);
  //   dispatch({
  //     type: GET_RPI_DATA_FAIL,
  //   });
  // }
};

export const getUzobestData = () => async (dispatch) => {
  // try {
  //   let res = await axios.get(`/api/v2/biller/uzobest/balance`, {
  //     baseURL: useURL3 || useURL,
  //   });
  //   dispatch({
  //     type: GET_UZOBEST_BALANCE,
  //     payload: res.data,
  //   });
  // } catch (err) {
  //   if (err) console.log(err.response?.data?.error, { err });
  //   if (err?.response?.status === 429 || err?.response?.status === 405)
  //     toast.error(err?.response?.data ? err?.response?.data : err?.message);
  //   dispatch({
  //     type: GET_UZOBEST_BALANCE_FAIL,
  //   });
  // }
};

export const getOgdams = () => async (dispatch) => {
  // try {
  //   let res = await axios.get(`/api/v2/biller/ogdams/balance`, {
  //     baseURL: useURL3 || useURL,
  //   });
  //   dispatch({
  //     type: GET_OGDAMS,
  //     payload: res.data,
  //   });
  // } catch (err) {
  //   if (err) console.log(err.response?.data?.error, { err });
  //   if (err?.response?.status === 429 || err?.response?.status === 405)
  //     toast.error(err?.response?.data ? err?.response?.data : err?.message);
  //   dispatch({
  //     type: GET_OGDAMS_FAIL,
  //   });
  // }
};

export const getRingo = () => async (dispatch) => {
  // try {
  //   let res = await axios.get(`/api/v2/biller/ringo/balance`, {
  //     baseURL: useURL2 || useURL,
  //   });
  //   dispatch({
  //     type: GET_RINGO_DATA,
  //     payload: res.data,
  //   });
  // } catch (err) {
  //   if (err) console.log(err.response?.data?.error, { err });
  //   if (err?.response?.status === 429 || err?.response?.status === 405)
  //     toast.error(err?.response?.data ? err?.response?.data : err?.message);
  //   dispatch({
  //     type: GET_RINGO_DATA_FAIL,
  //   });
  // }
};

export const getHonourworld = () => async (dispatch) => {
  try {
    let res = await axios.get(`/api/v2/biller/honourworld/balance`, {
      baseURL: useURL3 || useURL,
    });

    dispatch({
      type: GET_HONOURWORLD_DATA,
      payload: res.data,
    });
  } catch (err) {
    if (err) console.log(err.response?.data?.error, { err });
    if (err?.response?.status === 429 || err?.response?.status === 405)
      toast.error(err?.response?.data ? err?.response?.data : err?.message);
    dispatch({
      type: GET_HONOURWORLD_DATA_FAIL,
    });
  }
};

export const getMcd = () => async (dispatch) => {
  // try {
  //   let res = await axios.get(`/api/v2/biller/mcd/balance`, {
  //     baseURL: useURL3 || useURL,
  //   });
  //   dispatch({
  //     type: GET_MCD,
  //     payload: res.data,
  //   });
  // } catch (err) {
  //   if (err) console.log(err.response?.data?.error, { err });
  //   if (err?.response?.status === 429 || err?.response?.status === 405)
  //     toast.error(err?.response?.data ? err?.response?.data : err?.message);
  //   dispatch({
  //     type: GET_MCD_FAIL,
  //   });
  // }
};

export const getCategory = (data) => async (dispatch) => {
  try {
    if (data?.search)
      dispatch({ type: SEARCH_CATEGORY_LOADING, search: data?.search });
    let res = await axios.get(
      `/api/v2/category?type=all
			${!data?.limit ? "" : `&limit=${data.limit}`}${
        !data?.page ? "" : `&page=${data.page}`
      }
			${!data?.search ? "" : `&search=${data?.search}`}
			`,
      {
        baseURL: useURL3 || useURL,
      }
    );

    dispatch({
      type: data?.search ? SEARCH_CATEGORY : GET_CATEGORY,
      payload: res.data,
      search: data?.search
        ? res?.data?.search
          ? res?.data?.search
          : data?.search
        : "",
    });
  } catch (err) {
    if (err) console.log({ err });
    let error = err.response?.data?.error;
    if (data?.search) {
      if (error) {
        dispatch(returnErrors({ error, status: err?.response?.status }));
        // error.forEach(error =>
        // 	error?.param
        // 		? error?.param !== "suggestion" &&
        // 		  toast.error(error.msg, { autoClose: false })
        // 		: toast.error(error.msg, { autoClose: false })
        // );
      }
    }
    dispatch({
      type: data?.search ? SEARCH_CATEGORY_FAIL : GET_CATEGORY_FAIL,
    });
  }
};

export const manageCategory = (data, id, type) => async (dispatch) => {
  try {
    let res;
    if (!id)
      res = await axios.post(
        `/api/v2/category`,
        { ...data },
        {
          baseURL: useURL5 || useURL,
        }
      );
    else if (type === "delete")
      res = await axios.delete(`/api/v2/category/${id}`, {
        baseURL: useURL5 || useURL,
      });
    else if (type === "remove")
      res = await axios.delete(
        `/api/v2/category/${id}`,
        { data },
        {
          baseURL: useURL5 || useURL,
        }
      );
    else
      res = await axios.put(
        `/api/v2/category/${id}`,
        { ...data },
        {
          baseURL: useURL4 || useURL,
        }
      );
    dispatch({
      type: id
        ? type === "delete"
          ? DELETE_CATEGORY
          : UPDATE_CATEGORY
        : ADD_CATEGORY,
      payload: type === "delete" ? data : res.data?.data,
    });
    dispatch({ type: SET_SUCCESS, payload: res?.data?.msg });
    if (id === "switch-biller") {
      dispatch(getDataToBuy());
      dispatch(getDataBundle());
      dispatch(getElectricityBundle());
      dispatch(getElectricityToBuy());
      dispatch(getCablesPackages());
    }
  } catch (err) {
    console.log({ err });
    let error = err.response?.data?.error;
    if (error) {
      dispatch(returnErrors({ error, status: err?.response?.status }));
    }
    if (err?.response?.status === 429) toast.error(err?.response?.data);
    dispatch({ type: ADD_CATEGORY_FAIL });
  }
};

export const getBiller = (data) => async (dispatch) => {
  try {
    if (data?.search)
      dispatch({ type: SEARCH_BILLER_LOADING, search: data?.search });
    let res = await axios.get(
      `/api/v2/biller?type=all
			${!data?.limit ? "" : `&limit=${data.limit}`}${
        !data?.page ? "" : `&page=${data.page}`
      }
			${!data?.search ? "" : `&search=${data?.search}`}
			`,
      {
        baseURL: useURL3 || useURL,
      }
    );

    dispatch({
      type: data?.search ? SEARCH_BILLER : GET_BILLER,
      payload: res.data,
      search: data?.search
        ? res?.data?.search
          ? res?.data?.search
          : data?.search
        : "",
    });
  } catch (err) {
    if (err) console.log({ err });
    let error = err.response?.data?.error;
    if (data?.search) {
      if (error) {
        dispatch(returnErrors({ error, status: err?.response?.status }));
        // error.forEach(error =>
        // 	error?.param
        // 		? error?.param !== "suggestion" &&
        // 		  toast.error(error.msg, { autoClose: false })
        // 		: toast.error(error.msg, { autoClose: false })
        // );
      }
    }
    dispatch({
      type: data?.search ? SEARCH_BILLER_FAIL : GET_BILLER_FAIL,
    });
  }
};

export const manageBiller = (data, id, type) => async (dispatch) => {
  try {
    let res;
    if (!id)
      res = await axios.post(
        `/api/v2/biller`,
        { ...data },
        {
          baseURL: useURL3 || useURL,
        }
      );
    else if (type === "delete")
      res = await axios.delete(`/api/v2/biller/${id}`, {
        baseURL: useURL3 || useURL,
      });
    else
      res = await axios.put(
        `/api/v2/biller/${id}`,
        { ...data },
        {
          baseURL: useURL3 || useURL,
        }
      );
    dispatch({
      type: id
        ? type === "delete"
          ? DELETE_BILLER
          : UPDATE_BILLER
        : ADD_BILLER,
      payload: type === "delete" ? data : res.data?.data,
    });
    dispatch({ type: SET_SUCCESS, payload: res?.data?.msg });
  } catch (err) {
    console.log({ err });
    let error = err.response?.data?.error;
    if (error) {
      dispatch(returnErrors({ error, status: err?.response?.status }));
    }
    if (err?.response?.status === 429) toast.error(err?.response?.data);
    dispatch({ type: ADD_BILLER_FAIL });
  }
};

export const getBillerData = (data) => async (dispatch) => {
  try {
    let res =
      // data === "autosyncng"
      //   ? await axios.get("https://autosyncng.com/api/v1/data", {
      //       baseURL: useURL5 || useURL,
      //       headers: {
      //         Authorization: `Bearer 169|95dACvlylUDlQQ2O3fGK5iPEQhzgrlfbbIOeg3xha9dc1da6`,
      //       },
      //     })
      //   :
      await axios.get(`/api/v2/biller/${data}`, {
        baseURL: data === "ringo" ? useURL2 || useURL : useURL5 || useURL,
      });

    // console.log(res.data);

    // if (data === "autosyncng") {
    //   const transformedData = res.data.data.category?.products?.flatMap(
    //     (product) =>
    //       product.variations.map((variation) => ({
    //         planId: variation.code,
    //         name: variation.name,
    //         price: variation.amount.toFixed(2), // Converting amount to string with 2 decimal places
    //         networkId: product.id,
    //         network: product.code.toUpperCase(),
    //       }))
    //   );
    //   console.log(transformedData);
    //   dispatch({ type: GET_BILLER_AUTOSYNCNG, payload: transformedData });
    // }

    dispatch({
      type:
        data === "ringo"
          ? GET_BILLER_RINGO
          : data === "ogdams"
          ? GET_BILLER_OGDAMS
          : data === "smeplug"
          ? GET_BILLER_SMEPLUG
          : data === "chosen"
          ? GET_BILLER_CHOOSEN
          : data === "uzobest"
          ? GET_UZOBEST
          : data === "honourworld"
          ? GET_BILLER_HONOURWORLD
          : data === "rpi"
          ? GET_RPI_DATA_PLAN
          : data === "autosyncng"
          ? GET_BILLER_AUTOSYNCNG
          : null,
      payload: res.data,
    });
  } catch (err) {
    if (err) console.log({ err });
    let error = err.response?.data?.error;
    // console.log({ error });
    // if (data?.search) {
    // 	if (error) {
    // 		dispatch(returnErrors({ error, status: err?.response?.status }));
    // 	}
    // }
    dispatch({
      type: GET_BILLER_RINGO_FAIL,
    });
  }
};

export const getProducts = (data) => async (dispatch) => {
  try {
    if (data?.search)
      dispatch({ type: SEARCH_PRODUCTS_LOADING, search: data?.search });
    let res = await axios.get(
      `/api/v2/product?type=all
			${!data?.limit ? "" : `&limit=${data.limit}`}${
        !data?.page ? "" : `&page=${data.page}`
      }${!data?.page ? "" : `&page=${data.page}`}
			${!data?.search ? "" : `&search=${data?.search}`}
			`,
      {
        baseURL: useURL3 || useURL,
      }
    );

    dispatch({
      type: data?.search ? SEARCH_PRODUCTS : GET_PRODUCTS,
      payload: res.data,
      search: data?.search
        ? res?.data?.search
          ? res?.data?.search
          : data?.search
        : "",
    });
  } catch (err) {
    if (err) console.log({ err });
    let error = err.response?.data?.error;
    if (data?.search) {
      if (error) {
        dispatch(returnErrors({ error, status: err?.response?.status }));
      }
    }
    dispatch({
      type: data?.search ? SEARCH_PRODUCTS_FAIL : GET_PRODUCTS_FAIL,
    });
  }
};

export const manageProducts = (data, id, type) => async (dispatch) => {
  try {
    let res;
    if (!id)
      res = await axios.post(
        `/api/v2/product`,
        { ...data },
        {
          baseURL: useURL3 || useURL,
        }
      );
    else if (type === "delete")
      res = await axios.delete(`/api/v2/product/${id}`, {
        baseURL: useURL3 || useURL,
      });
    else
      res = await axios.put(
        `/api/v2/product/${id}`,
        { ...data },
        {
          baseURL: useURL3 || useURL,
        }
      );
    dispatch({
      type: id
        ? type === "delete"
          ? DELETE_PRODUCTS
          : UPDATE_PRODUCTS
        : ADD_PRODUCTS,
      payload: type === "delete" ? data : res.data?.data,
    });
    dispatch({ type: SET_SUCCESS, payload: res?.data?.msg });
  } catch (err) {
    console.log({ err });
    let error = err.response?.data?.error;
    if (error) {
      dispatch(returnErrors({ error, status: err?.response?.status }));
    }
    if (err?.response?.status === 429) toast.error(err?.response?.data);
    dispatch({ type: ADD_PRODUCTS_FAIL });
  }
};

export const toggleAvailabilty = (data, type) => async (dispatch) => {
  try {
    let res = await axios.put(
      `/api/v2/${data}?enabler=${type}`,
      {},
      {
        baseURL: useURL4 || useURL,
      }
    );

    dispatch({
      type:
        data === "data"
          ? GET_DATA_BUNDLE
          : data === "category"
          ? GET_CATEGORY
          : data === "product"
          ? GET_PRODUCTS
          : null,
      payload: res.data,
    });
    dispatch({ type: SET_SUCCESS, payload: res?.data?.msg });
  } catch (err) {
    console.log({ err });
    let error = err.response?.data?.error;
    if (error) {
      dispatch(returnErrors({ error, status: err?.response?.status }));
    }
    if (err?.response?.status === 429) toast.error(err?.response?.data);
    dispatch({
      type:
        data === "data"
          ? GET_DATA_BUNDLE_FAIL
          : data === "category"
          ? GET_CATEGORY_FAIL
          : data === "product"
          ? GET_PRODUCTS_FAIL
          : null,
    });
  }
};

export const getProviderStat = (data) => async (dispatch) => {
  try {
    let res = await axios.get(
      `/api/v2/${
        ["/manage-wallet-provider", "/manage-commission"]?.includes(data?.route)
          ? "wallet"
          : "transactions"
      }${data?.route}${data?.year ? `?year=${data?.year}` : ""}`,
      {
        baseURL: useURL5 || useURL,
      }
    );

    dispatch({
      type:
        data?.route === "/manage-transactions"
          ? TRANSACTIONS_STAT
          : data?.route === "/manage-commission"
          ? COMMISSION_TRANSACTIONS_STAT
          : WALLET_PROVIDER_STAT,
      payload: res.data,
    });
  } catch (err) {
    if (err) console.log(err.response?.data?.error, { err });
    if (err?.response?.status === 429 || err?.response?.status === 405)
      toast.error(err?.response?.data ? err?.response?.data : err?.message);
  }
};

export const findProviderStat = (data) => async (dispatch) => {
  try {
    let res = await axios.post(
      `/api/v2/transactions/manage-transactions${
        data?.year ? `?year=${data?.year}` : ""
      }`,
      { ...data },
      {
        baseURL: useURL6 || useURL,
      }
    );

    dispatch({
      type: FIND_TRANSACTIONS_STAT,
      payload: res.data,
    });
  } catch (err) {
    if (err) console.log(err.response?.data?.error, { err });
    if (err?.response?.status === 429 || err?.response?.status === 405)
      toast.error(err?.response?.data ? err?.response?.data : err?.message);
    let error = err.response?.data?.error;
    if (error) {
      dispatch(returnErrors({ error, status: err?.response?.status }));
    }
  }
};

export const getDataTransactionStat = (data, user) => async (dispatch) => {
  try {
    let res;
    if (user) {
      res = await axios.put(
        `/api/v2/user/manage-users${data?.year ? `?year=${data?.year}` : ""}`,
        { ...data },
        {
          baseURL: useURL6 || useURL,
        }
      );
    } else {
      res = await axios.patch(
        `/api/v2/transactions/data${data?.year ? `?year=${data?.year}` : ""}`,
        { ...data },
        {
          baseURL: useURL6 || useURL,
        }
      );
    }

    dispatch({
      type: user ? USER_REGISTRATION_STAT : DATA_TRANSACTIONS_STAT,
      payload: res.data,
    });
  } catch (err) {
    if (err) console.log(err.response?.data?.error, { err });
    if (err?.response?.status === 429 || err?.response?.status === 405)
      toast.error(err?.response?.data ? err?.response?.data : err?.message);
    let error = err.response?.data?.error;
    if (error) {
      dispatch(returnErrors({ error, status: err?.response?.status }));
    }
  }
};

// export const getNetwork = () => async (dispatch) => {
//   try {
//     let res = await axios.get(`/api/v2/network`, {
//       baseURL: useURL3 || useURL,
//     });

//     dispatch({
//       type: GET_NETWORK,
//       payload: res.data,
//     });
//   } catch (err) {
//     if (err) console.log(err.response?.data?.error, { err });
//     if (err?.response?.status === 429 || err?.response?.status === 405)
//       toast.error(err?.response?.data ? err?.response?.data : err?.message);
//   }
// };

export const getNetwork = () => (dispatch) => {
  const res = networkData;

  dispatch({
    type: GET_NETWORK,
    payload: res,
  });
};

export const manageNetwork = (data, id, type) => async (dispatch) => {
  try {
    let res;
    if (!id) {
      let media = await imageUpload([data?.image]),
        image = media[0];
      res = await axios.post(
        `/api/v2/network`,
        { ...data, image },
        {
          baseURL: useURL3 || useURL,
        }
      );
    } else if (type === "delete")
      res = await axios.delete(`/api/v2/network/${id}`, {
        baseURL: useURL3 || useURL,
      });
    else {
      let image;
      if (data?.image)
        if (!data?.image?._id) {
          let media = await imageUpload([data?.image]);
          image = media[0];
        }
      res = await axios.put(
        `/api/v2/network/${id}`,
        {
          ...data,
          image: image ? image : data?.image,
        },
        {
          baseURL: useURL3 || useURL,
        }
      );
    }

    dispatch({
      type: id
        ? type === "delete"
          ? DELETE_NETWORK
          : UPDATE_NETWORK
        : ADD_NETWORK,
      payload: type === "delete" ? data : res.data?.data,
    });
    dispatch({ type: SET_SUCCESS, payload: res?.data?.msg });
  } catch (err) {
    console.log({ err });
    let error = err.response?.data?.error;
    if (error) {
      dispatch(returnErrors({ error, status: err?.response?.status }));
    }
    if (err?.response?.status === 429) toast.error(err?.response?.data);
    dispatch({ type: ADD_NETWORK_FAIL });
  }
};

export const getBillerFunding = (data) => async (dispatch) => {
  try {
    let res;

    if (!data)
      res = await axios.get(`/api/v2/biller/manage-biller-funding`, {
        baseURL: useURL3 || useURL,
      });
    else
      res = await axios.post(
        `/api/v2/biller/manage-biller-funding`,
        {
          ...data,
        },
        {
          baseURL: useURL3 || useURL,
        }
      );

    dispatch({
      type: data ? ADD_BILLER_FUNDING : GET_BILLER_FUNDING,
      payload: res.data,
    });
    if (data) dispatch({ type: SET_SUCCESS, payload: res?.data?.msg });
  } catch (err) {
    if (err) console.log({ err });
    let error = err.response?.data?.error;
    if (data?.search) {
      if (error) {
        dispatch(returnErrors({ error, status: err?.response?.status }));
      }
    }
    dispatch({
      type: ADD_BILLER_FUNDING_FAIL,
    });
  }
};

export const findProviderFunding = (data) => async (dispatch) => {
  if (data?.search)
    dispatch({ type: SEARCH_PROVIDER_LOADING, search: data?.search });
  try {
    let res = await axios.post(
      `/api/v2/wallet/manage-wallet-provider
			${data?.provider ? `?provider=${data?.provider}` : ""}
			${data?.search ? `&search=${data?.search}` : ""}
			${data?.limit ? `&limit=${data?.limit}` : ""}${
        !data?.page ? "" : `&page=${data.page}`
      }
			`,
      { ...data },
      {
        baseURL: useURL5 || useURL,
      }
    );

    dispatch({
      type: data?.search
        ? SEARCH_PROVIDER
        : data?.provider === "monnify"
        ? GET_MONNIFY
        : data?.provider === "flutterwave"
        ? GET_FLUTTERWAVE
        : data?.provider === "paystack"
        ? GET_PAYSTACK
        : data?.provider === "budpay"
        ? GET_BUDPAY
        : data?.provider === "transfer"
        ? GET_TRANSFER
        : data?.provider === "manual"
        ? GET_MANUAL
        : null,
      payload: res.data,
      data,
      search: data?.search
        ? res?.data?.search
          ? res?.data?.search
          : data?.search
        : "",
    });
  } catch (err) {
    if (err) console.log(err.response?.data?.error, { err });
    if (err?.response?.status === 429 || err?.response?.status === 405)
      toast.error(err?.response?.data ? err?.response?.data : err?.message);
    let error = err.response?.data?.error;
    if (error && data?.search) {
      dispatch(returnErrors({ error, status: err?.response?.status }));
    }
    dispatch({ type: data?.search ? SEARCH_PROVIDER_FAIL : GET_PROVIDER_FAIL });
  }
};

export const getGateway = (data) => async (dispatch) => {
  try {
    if (data?.search)
      dispatch({ type: SEARCH_GATEWAY_LOADING, search: data?.search });
    let res = await axios.get(
      `/api/v2/gateway?type=all
			${!data?.limit ? "" : `&limit=${data.limit}`}${
        !data?.page ? "" : `&page=${data.page}`
      }
			${!data?.search ? "" : `&search=${data?.search}`}
			`,
      {
        baseURL: useURL3 || useURL,
      }
    );

    dispatch({
      type: data?.search ? SEARCH_GATEWAY : GET_GATEWAY,
      payload: res.data,
      search: data?.search
        ? res?.data?.search
          ? res?.data?.search
          : data?.search
        : "",
    });
  } catch (err) {
    if (err) console.log({ err });
    let error = err.response?.data?.error;
    if (data?.search) {
      if (error) {
        dispatch(returnErrors({ error, status: err?.response?.status }));
        // error.forEach(error =>
        // 	error?.param
        // 		? error?.param !== "suggestion" &&
        // 		  toast.error(error.msg, { autoClose: false })
        // 		: toast.error(error.msg, { autoClose: false })
        // );
      }
    }
    dispatch({
      type: data?.search ? SEARCH_GATEWAY_FAIL : GET_GATEWAY_FAIL,
    });
  }
};

export const manageGateway = (data, id, type) => async (dispatch) => {
  try {
    let res;
    if (!id)
      res = await axios.post(
        `/api/v2/gateway`,
        { ...data },
        {
          baseURL: useURL3 || useURL,
        }
      );
    else if (type === "delete")
      res = await axios.delete(`/api/v2/gateway/${id}`, {
        baseURL: useURL3 || useURL,
      });
    else
      res = await axios.put(
        `/api/v2/gateway/${id}`,
        { ...data },
        {
          baseURL: useURL3 || useURL,
        }
      );
    dispatch({
      type: id
        ? type === "delete"
          ? DELETE_GATEWAY
          : UPDATE_GATEWAY
        : ADD_GATEWAY,
      payload: type === "delete" ? data : res.data?.data,
    });
    dispatch({ type: SET_SUCCESS, payload: res?.data?.msg });
  } catch (err) {
    console.log({ err });
    let error = err.response?.data?.error;
    if (error) {
      dispatch(returnErrors({ error, status: err?.response?.status }));
    }
    if (err?.response?.status === 429) toast.error(err?.response?.data);
    dispatch({ type: ADD_GATEWAY_FAIL });
  }
};
